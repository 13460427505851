<script>
  import {
    locale,
    _,
    getLocaleFromQueryString,
    getLocaleFromNavigator,
  } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";

  export let language;

  const dispatch = createEventDispatcher();

  // probably get from the url by default else from the browser language else english

  let localeFromQuery = getLocaleFromQueryString("lang");

  function defaultLanguage() {
    let localeFromBrowser = getLocaleFromNavigator();
    let localeFromStorage = localStorage.getItem("enrol-app-locale");
    let localeDefault = "en";

    if (language) {
      return language;
    }
    if (localeFromStorage) {
      return localeFromStorage;
    }

    if (localeFromBrowser) {
      return localeFromBrowser.slice(0, 2);
    }

    return localeDefault;
  }

  switch (localeFromQuery) {
    case "en":
      language = "en";
      break;
    case "fr":
      language = "fr";
      break;
    case "it":
      language = "it";
      break;
    case "nl":
      language = "nl";
      break;
    case "de":
      language = "de";
      break;
    default:
      language = defaultLanguage();
      break;
  }

  changeLocale();

  function changeLocale() {
    locale.set(language);
    localStorage.setItem("enrol-app-locale", language);
    dispatch("languageChange", language);
  }
</script>

<h2>{$_("language.Please Select Language Version")}:</h2>
<div class="dropdown">
  <select bind:value={language} on:change={changeLocale}>
    <option value="en">🇬🇧 {@html $_("language.en")}</option>
    <option value="fr">🇫🇷 {@html $_("language.fr")}</option>
    <option value="de">🇩🇪 {@html $_("language.de")}</option>
    <option value="it">🇮🇹 {@html $_("language.it")}</option>
    <option value="nl">🇳🇱 {@html $_("language.nl")}</option>
  </select>
  <div class="chevron">
    <span>&#9662;</span>
  </div>
</div>

<style>
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }

  .chevron {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .chevron span {
    font-size: 12px;
  }
</style>
